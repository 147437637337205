<template>
  <div>
    <a-card title="原辅料使用记录">
      <a-row :gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="编号, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button icon="edit" @click="exportExcels()">导出</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">新增</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
                size="small"
                :pagination="pagination"
                :columns="columns"
                :dataSource="items"
                rowKey="id"
                :loading="loading"
                @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button size="small" @click="detial(item)">详情</a-button>
              <a-popconfirm title="确定作废吗" @confirm="voidItem(item)">
                <a-button v-if="!item.is_void" type="danger" size="small">作废</a-button>
                <a-button v-else disabled size="small">已作废</a-button>
              </a-popconfirm>
              <!-- <a-button icon="edit" size="small" @click="openFormModal(item)">详情</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">作废</a-button>
              </a-popconfirm>
              <a-button icon="edit" size="small" @click="exportExcel(item)">导出</a-button> -->
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <form-modal
            v-if="visible"
            v-model="visible"
            :form="targetItem"
            :clientsClassificationOptions="clientsClassificationItems"
            @create="create"
            @update="update"
    />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
  function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    let clonedObj = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = deepClone(obj[key]);
      }
    }

    return clonedObj;
  }
  import { downloadByUrl } from '@/utils/upload.js'
  import { saleExport } from "@/api/produce";
  import { saleOrderList, saleExport1, saleOrdersVoid } from "@/api/sale";
  import { exportExcel1 } from "@/utils/excel";
  import { outboundList, outboundDel } from "@/api/produce";

  export default {
    components: {
      FormModal: () => import("./FormModal.vue"),
    },
    data() {
      return {
        supplierId: null,
        expandedRowKeys: [],
        selectedRowKeys: [],
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '编号',
            dataIndex: 'number',
            sorter: true,
          },
          {
            title: '领用方',
            dataIndex: 'client_name',
          },
          {
            title: '经手人',
            dataIndex: 'handler_name',
          },
          {
            title: '发生日期',
            dataIndex: 'handle_time',
            width: 150
          },
          {
            title: '总数量',
            dataIndex: 'total_quantity',
            width: 120
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 200
          },
        ],
        pagination: { current: 1, total: 0, pageSize: 10 },
        searchForm: { page: 1, page_size: 16 },
        loading: false,
        items: [],
        clientsClassificationItems: [],
        visible: false,
        targetItem: { others: {} },
        form: { others: {} },
        importLoading: false,
      };
    },
    computed: {},
    methods: {
      detial(item) {
        this.$router.push({ path: '/produce/outboundDetail', query: { id: item.id } });
      },
      voidItem(item) {
        saleOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys
      },
      async exportExcels() {
        if(this.selectedRowKeys.length == 0) {
          this.$message.warn('请选选择需要导出的数据')
          return;
        } else{
          const data = await saleExport({ ids: this.selectedRowKeys, name: 'outbound', types: 'word' });
          const url = data.data;
          const name = url.split('/').pop();
          downloadByUrl(url, name);
        }
      },
      exportExcel(item) {
        saleExport1({ id: item.id, name: 'outbound', types: 'word' })
                .then((data) => {
                  let contentDisposition = data.headers['content-disposition'];
                  let regex = /filename\s*=\s*([^;]+)/;
                  let matches = contentDisposition.match(regex);
                  let filename = matches && matches[1].trim();
                  exportExcel1(data.data, filename);
                })
                .catch((err) => {
                  this.$message.error(err.response.data.error);
                });
      },
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        saleOrderList(Object.assign({}, this.searchForm, { search: '自家工厂车间' }))
                .then((data) => {
                  this.pagination.total = data.count;
                  this.items = data.results;
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      create(item) {
        this.list();
      },
      update() {
        this.list();
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.list();
      },
      openFormModal(item) {
        this.targetItem = deepClone(item)
        this.visible = true;
      },
      destroy(id) {
        outboundDel({ id: id })
                .then((data) => {
                  this.$message.success("删除成功");
                  this.list();
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
        this.list();
      },
    },
    mounted() {
      this.initialize();
    },
  };
</script>
